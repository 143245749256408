import styled from 'styled-components';

export const Container = styled.div`
    min-width: 320px;
    width: 90%;
    margin: auto;
`;

export const Header = styled.div`
    width: 100%;
    border-bottom: 1px solid #434343;

    a {
        text-decoration: none;
    }
`;

export const HeaderContent = styled.div`
    display: grid;
    grid-template-columns: 80px 1fr;
    width: fit-content;
    margin: auto;
`;

export const HeaderLogo = styled.img`
    height: 60px;
    padding: 20px;
    justify-self: end;
    filter: drop-shadow(0px 0px 4px #434343);
`;

export const HeaderTitle = styled.div`
    display: inline-block;
    color: #434343;
    font-size: 3em;
    font-weight: bold;
    margin: 0;
    padding: 0;
    height: 100px;
    line-height: 100px;
`;

const footerHeight = 60;
export const Footer = styled.div`
    border-top: 1px solid #434343;
    height: ${footerHeight}px;
    width: 100%;
`;

export const FooterContent = styled.div`
    height: 100%;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 20px;
    margin: auto;
`;

export const FooterLink = styled.span`
    a {
        color: #666;
        font-size: 0.8em;
        text-decoration: none;
        line-height: ${footerHeight}px;

        &:hover {
            text-decoration: underline;
        }
    }
`;
