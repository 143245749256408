import { useCallback } from 'react';
import {
    Content,
    ScreenRecordingsContainer,
    ScreenRecordingDescription,
    TextContainer,
    SubTitle,
    Description,
    Bold,
    StoreBadge
} from './styles';

const Home = () => {
    const videoString = useCallback((src: string) => {
        return `
            <video style="width: 100%; max-width: 480px" loop muted autoPlay playsinline>
                <source src="${src}" type="video/mp4" />
            </video>
        `;
    }, []);

    return (
        <Content>
            <TextContainer>
                <SubTitle>QuickLines makes it easy to draw lines on your screen to align stuff.</SubTitle>
                <Description>Simply hold your hotkeys (defaults to <Bold>⌘ cmd</Bold> + <Bold>⌥ opt</Bold>) and drag a new line from the side or the top of your screen. Drag it back to the rulers to delete it.</Description>
                <a target="_blank" href="https://apps.apple.com/us/app/quicklines/id1604117361"><StoreBadge src="assets/mac_app_store_badge.svg" /></a>
            </TextContainer>

            <ScreenRecordingsContainer>
                <span>
                    <ScreenRecordingDescription>Create a new line</ScreenRecordingDescription>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: videoString("assets/createLine_2_cropped.mp4")
                        }}
                    />

                </span>

                <span>
                    <ScreenRecordingDescription>Remove a line</ScreenRecordingDescription>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: videoString("assets/removeLine_2_cropped.mp4")
                        }}
                    />
                </span>
            </ScreenRecordingsContainer>
        </Content>
    )
}

export default Home;
