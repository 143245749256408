import styled from 'styled-components';

export const Content = styled.div`
    margin: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const TextContainer = styled.div`
    text-align: center;
`;

export const SubTitle = styled.p`
    text-align: center;
    margin: 30px;
    font-size: 1.5em;
`;

export const Description = styled.p`
    max-width: 560px;
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
`;

export const Bold = styled.span`
    font-weight: bold;
`;

export const StoreBadge = styled.img`
    height: 45px;
`;

export const ScreenRecordingsContainer = styled.div`
    display: grid;
    justify-items: center;
    grid-template-columns: 480px 480px;
    column-gap: 20px;
    margin: auto;
    width: fit-content;
    margin-top: 20px;
    
    @media (max-width: 1024px) {
        grid-template-columns: 90%;
        row-gap: 20px;
        justify-content: center;
    }
`;

export const ScreenRecordingDescription = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 10px;
`;
