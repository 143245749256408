import styled from 'styled-components';

export const Content = styled.div`
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 40px;

    h1 {
        margin-bottom: 40px;
    }

    h2 {
        margin-top: 40px;
    }

    p {
        text-align: justify;
    }
    
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;