import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from '../Home';
import Markdown from '../Markdown';
import privacySource from '../../assets/privacy.md';

import {
    Container,
    Header,
    HeaderContent,
    HeaderLogo,
    HeaderTitle,
    Footer,
    FooterContent,
    FooterLink
} from './styles';

const App = () =>
    <Router>
        <Container>
            <Header>
                <Link to="/">
                    <HeaderContent>
                        <HeaderLogo src='assets/logo_512.png' />
                        <HeaderTitle>QuickLines</HeaderTitle>
                    </HeaderContent>
                </Link>
            </Header>

            <Routes>
                <Route key="privacy" path='/privacy' element={<Markdown source={privacySource} />} />
                <Route key="home" path='/' element={<Home />} />
            </Routes>

            <Footer>
                <FooterContent>
                    <FooterLink><Link to="/privacy">Privacy Policy</Link></FooterLink>
                    <FooterLink><a href="mailto:contact@quicklines.app">Contact</a></FooterLink>
                    <FooterLink><a target="_blank" href="https://www.blockchain.com/btc/address/bc1q03qqnkjxczwf70dkm8ph32d5dk73x6ranpezp3">Donate Bitcoin</a></FooterLink>
                </FooterContent>
            </Footer>
        </Container>
    </Router >

export default App;
