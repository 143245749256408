import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Content } from './styles';

export type MarkdownProps = {
    source: string
}

const Markdown = ({ source }: MarkdownProps) => {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(source)
            .then(res => res.text())
            .then((text) => setContent(text));
    }, [source]);

    return (
        <Content>
            <ReactMarkdown children={content} />
        </Content>
    );
}

export default Markdown;
